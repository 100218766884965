<template>
  <div class="flex flex-wrap-wrap height-q" v-loading="loading">
    <div style="width: 30%; background-color: #fff; margin-left: 1%">
      <div class="width-100 height-q margin-top-sm">
        <ul class="width-100">
          <li
            class="flex flex-center cursor"
            @click="handleNewRole"
            style="background-color: #eaeaea; height: 30px"
          >
            <span class="el-icon-plus"></span>
            新建{{ typeTitle }}
          </li>
          <li
            v-for="(item, index) in roleMessage"
            :key="index"
            :class="{ 'color-box1': currentRole.id === item.id }"
            class="width-100 cursor padding-xs"
            @click="selectRoles(item, index)"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>

    <div class="border-left-grey bg-white" style="width: 65%; margin-left: 1%">
      <h1 style="text-align: center; font-size: 16px" v-show="currentRole.name">
        {{ currentRole.name }}
      </h1>
      <div class="height-q margin-top-sm">
        <el-form ref="form" :model="form" label-width="80px" v-if="!isCreate">
          <el-form-item :label="typeTitle + '名称'">
            <el-input
              placeholder="最多可输入15个字"
              maxlength="15"
              v-model="Roleform.name"
            ></el-input>
          </el-form-item>
          <el-form-item :label="typeTitle + '标识'" v-show="type == 1">
            <el-select v-model="Roleform.sys_tag">
              <el-option
                v-for="item in roleTypes"
                :key="item.Name"
                :value="item.Name"
                :label="item.Name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="适用校区">
            <el-radio-group v-model="radio">
              <el-radio :label="0">全部校区</el-radio>
              <el-radio :label="1">指定校区</el-radio>
            </el-radio-group>

            <div>
              <i class="iconfont icon-yuandianxiao text-green"></i>
              <span class="text-green"
                >修改{{ typeTitle }}的适用校区不影响员工已有{{
                  typeTitle
                }}，如需修改员工账号请编辑员工</span
              >
            </div>
          </el-form-item>
          <el-form-item label="" v-if="radio === 1">
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
              >全部校区</el-checkbox
            >
            <el-checkbox-group
              v-model="school_ids"
              @change="handleCheckedChange"
            >
              <el-checkbox
                :label="item.id"
                v-for="(item, index) in organizationList"
                :key="index"
              >
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="选择权限">
            <el-tree
              ref="dicTree"
              :data="AuthorityList"
              node-key="id"
              :default-checked-keys="checkedRoleIds"
              :default-expand-all="true"
              show-checkbox
              :props="Props"
              @check-change="handleNodeClick"
            >
            </el-tree>
          </el-form-item>
        </el-form>

        <el-form ref="Roleform" :model="Roleform" label-width="80px" v-else>
          <el-form-item :label="typeTitle + '名称'">
            <el-input
              placeholder="最多可输入15个字"
              maxlength="15"
              v-model="Roleform.name"
            ></el-input>
          </el-form-item>
          <el-form-item :label="typeTitle + '标识'" v-show="type == 1">
            <el-select v-model="Roleform.sys_tag">
              <el-option
                v-for="item in roleTypes"
                :key="item.Name"
                :value="item.Name"
                :label="item.Name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="适用校区">
            <el-radio-group v-model="radio">
              <el-radio :label="0">全部校区</el-radio>
              <el-radio :label="1">指定校区</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="" v-if="radio === 1">
            <el-checkbox v-model="checkAll" @change="handleCheckAllChange"
              >全部校区</el-checkbox
            >
            <el-checkbox-group
              v-model="school_ids"
              @change="handleCheckedChange"
            >
              <el-checkbox
                :label="item.id"
                v-for="(item, index) in organizationList"
                :key="index"
              >
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
        <div class="flex flex-center margin-top-xl padding-bottom">
          <el-button type="primary" @click="addRole">保存</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RolePermission",
  props: {
    //类型：0角色，1岗位
    type: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      radio: "",
      radio2: "",
      checkAll: false,
      Props: {
        children: "children",
        label: "title",
      },
      listQuerys: {
        module: "RolePermissions",
        parentId: 365,
      },
      SchoolQuery: {
        type: 1,
        PageIndex: 1,
        PageSize: 100000,
        Sort: [
          {
            Field: "id",
            Type: 0,
          },
        ],
      },
      form: {},
      isCreate: false,
      AuthorityList: [], //权限列表
      organizationList: [], //所有的校区
      roleMessage: [], //所有的角色
      currentRole: {},
      school_ids: [], //选中的校区
      checkedRoleIds: [], //选中的角色id
      Roleform: {
        name: "",
        school_id: 0,
        sys_tag: "",
      }, //角色的添加表单
      roleTypes: [],
      loading: false,
    };
  },
  created() {
    this.getSchool();
    this.getRoleList();
    this.getAllRoles();
    this.getRoleTypes();
  },
  watch: {
    checkedRoleIds: {
      deep: true,
      handler(val) {
        this.$refs.dicTree.setCheckedKeys(val.map((t) => t.id));
      },
    },
  },
  computed: {
    typeTitle() {
      return this.type === 0 ? "角色" : "岗位";
    },
  },
  methods: {
    getRoleTypes() {
      this.API.SystemGetSettings("SysPostType", true).then((res) => {
        this.roleTypes = res;
      });
    },
    getRoleDictionaryIds(id) {
      this.API.getRoleDicIds({
        role_id: id,
        dic_parent_id: 365,
      }).then((res) => {
        this.checkedRoleIds = res.data || [];
      });
    },
    handleCheckAllChange(val) {
      this.school_ids = val ? this.organizationList.map((t) => t.id) : [];
    },
    getAllRoles() {
      this.getRoleQuery({
        PageIndex: 1,
        PageSize: 100000,
      }).then((res) => {
        this.roleMessage = res.data.rows.filter((t) => t.type === this.type);
        this.selectRoles(res.data.rows[0]);
      });
    },
    getRoleTree(data) {
      return new Promise((resolve, reject) => {
        this.$Dictionary.GetDictionaryTree(data).then((res) => {
          resolve(res.data);
        });
      });
    },
    handleNodeClick(data, checked, indeterminate) {
      let index = this.checkedRoleIds.findIndex((t) => t == data.id);
      if (checked) {
        if (index == -1) {
          this.checkedRoleIds.push(data.id);
        }
      } else {
        if (index > -1) {
          this.checkedRoleIds.splice(index, 1);
        }
      }
      console.log(data, checked, indeterminate);
    },
    addRole() {
      this.loading = true;
      if (this.isCreate) {
        let formData = {
          ...this.Roleform,
          type: this.type,
        };
        this.API.roleSaveRole(formData)
          .then((res) => {
            if (res.code == 200) {
              this.$message.success(`添加${this.typeTitle}成功`);
              this.Roleform = {};
              this.isCreate = true;
              this.getAllRoles();
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        let schoolids = this.school_ids.filter((t) => t > 0).join(",");
        this.API.SetRoleDicPermission({
          role_id: this.currentRole.id,
          Dict_id: this.checkedRoleIds,
          school_id: this.radio > 0 ? schoolids : 0,
        })
          .then((res) => {
            this.$message.success("操作成功");
          })
          .finally(() => {
            this.loading = false;
          });

		  //编辑岗位
		  let formData = {
          ...this.Roleform,
          type: this.type,
        };
        this.API.roleSaveRole(formData)
          .then((res) => {
            if (res.code == 200) {
              this.Roleform = {};
              this.isCreate = false;
              this.getAllRoles();
            }
          })

      }
    },
    handleCheckedChange() {
      this.checkAll = this.school_ids.length === this.organizationList.length;
    },
    getRoleQuery(data) {
      return new Promise((resolve, reject) => {
        this.API.roleQuery(data).then((res) => {
          resolve(res);
        });
      });
    },

    //获取权限
    getRoleList(parentId) {
      let listQuery = {
        ...this.listQuerys,
      };
      if (parentId) {
        listQuery.parentId = parentId;
      }
      this.getRoleTree(listQuery).then((res) => {
        if (parentId) {
          this.AuthorityList.find((t) => t.id == parentId).children = res;
        } else {
          this.AuthorityList = res;
        }
        this.AuthorityList = JSON.parse(JSON.stringify(this.AuthorityList));
        res.forEach((item) => {
          if (item.leaf) {
            this.getRoleList(item.id);
          }
        });
      });
    },
    handleNewRole() {
      this.currentRole = {};
      this.isCreate = true;
    },
    getSchool() {
      this.API.OrganizationList(this.SchoolQuery).then((res) => {
        console.log("organizationList", res.data.rows);
        this.organizationList = res.data.rows;
      });
    },
    selectRoles(item) {
      this.currentRole = item;
      this.Roleform = item;
      this.checkedRoleIds = JSON.parse(JSON.stringify([]));
      this.getRoleDictionaryIds(item.id);
      this.isCreate = false;
      if (item.school_id == "0") {
        this.radio = 0;
      } else {
        this.radio = 1;
        this.school_ids = JSON.parse(
          JSON.stringify(item.school_id.split(",").map((t) => Number(t)))
        );
      }
    },
  },
};
</script>
