<template>
	<div class="total">
		<div style="position: absolute;z-index: 9;top:10px;right: 10px;">
			<el-button type="primary" size="mini" @click="goNav"> 新建员工 </el-button>
		</div>
		<el-tabs v-model=" activeName" type="card">
			<el-tab-pane disabled label="员工" name="first3"> </el-tab-pane>
			<el-tab-pane label="员工管理" name="first">
				<div class=" flex justify-content-between aligin-items-center margin-lr-lg">
					<div class="work-title-content flex aligin-items-center flex-wrap-wrap">
						<el-popover width="150" trigger="click">
							<el-checkbox-group v-model="orgids" @change="orgids_change">
								<el-checkbox class="padding-xs" :label="item.name" 
									v-for="(item, index) in OrganizationList" :key="index" />
							</el-checkbox-group>
							<div class="cursor" slot="reference">
								<span>所属部门</span>
								<i class="el-icon-caret-bottom" />
								<span class="border-right" />
							</div>
						</el-popover>
						<el-popover width="200" trigger="click">
							<el-checkbox-group v-model="orgids"  @change="orgids_change_school" >
								<el-checkbox class="padding-xs" :label="item.name" v-for="(item, index) in organizationList" :key="index"/>
							</el-checkbox-group>
							<div class="cursor" slot="reference">
								<span>管辖校区</span>
								<i class="el-icon-caret-bottom" />
								<span class="border-right" />
							</div>
						</el-popover>
						<el-popover width="160" trigger="click">
							<el-input v-model="assistantInput" placeholder="" size="mini"></el-input>
							<div style="height: 150px; overflow-y: auto;">
								<el-checkbox-group v-model="post_type" @change="post_type_change">
									<el-checkbox class="padding-xs" :label="item.name" v-for="(item, index) in roleMessage"
									:key="index"/>
								</el-checkbox-group>
							</div>
							<div class="cursor" slot="reference">
								<span>角色</span>
								<i class="el-icon-caret-bottom" />
								<span class="border-right" />
							</div>
						</el-popover>
						<el-popover width="160" trigger="click">
							<div style="height: 150px; overflow-y: auto;">
								<el-checkbox-group v-model="personnel_status"  @change="personnel_status_change">
									<el-checkbox class="padding-xs" :label="item.Name"  v-for="(item, index) in PersonnelMattersList" :key="index"/>
								</el-checkbox-group>
							</div>
							<div class="cursor" slot="reference">
								<span>人事状态</span>
								<i class="el-icon-caret-bottom" />
								<span class="border-right" />
							</div>
						</el-popover>
						<el-popover v-if="More" width="60" trigger="click">
							<el-radio-group v-model="birthday" @change="birthday_change">
								<el-radio class="padding-xs" label="本月生日" />
								<el-radio class="padding-xs" label="下月生日" />
							</el-radio-group>
							<div class="cursor" slot="reference">
								<span>生日</span>
								<i class="el-icon-caret-bottom" />
								<span class="border-right" />
							</div>
						</el-popover>
						<el-popover v-if="More" width="60" trigger="click">
							<el-radio-group v-model="has_contract"  @change="has_contract_change">
								<el-radio class="padding-xs" :label="true">已签</el-radio>
								<el-radio class="padding-xs" :label="false">未签</el-radio>
							</el-radio-group>
							<div class="cursor" slot="reference">
								<span>合同</span>
								<i class="el-icon-caret-bottom" />
								<span class="border-right" />
							</div>
						</el-popover>
						<el-popover v-if="More" width="60" trigger="click">
							<el-radio-group v-model="expire_contract" @change="expire_contract_change">
								<el-radio class="padding-xs" label="本周到期"></el-radio>
								<el-radio class="padding-xs" label="本月到期"></el-radio>
								<el-radio class="padding-xs" label="下月到期"></el-radio>
							</el-radio-group>
							<div class="cursor" slot="reference">
								<span>合同到期</span>
								<i class="el-icon-caret-bottom" />
								<span class="border-right" />
							</div>
						</el-popover>
						<el-popover v-if="More" width="60" trigger="click">
							<el-radio-group v-model="has_social_security" @change="has_social_security_change">
								<el-radio class="padding-xs" :label="true">已办理</el-radio>
								<el-radio class="padding-xs" :label="false">未办理</el-radio>
							</el-radio-group>
							<div class="cursor" slot="reference">
								<span>社保</span>
								<i class="el-icon-caret-bottom" />
								<span class="border-right" />
							</div>
						</el-popover>
						<el-popover v-if="More" width="60" trigger="click">
							<el-checkbox-group v-model="labor_relations" @change="labor_relations_change">
								<el-checkbox :label="0">全职</el-checkbox>
								<el-checkbox :label="1">兼职</el-checkbox>
								<el-checkbox :label="2">合作</el-checkbox>
							</el-checkbox-group>
							<div class="cursor" slot="reference">
								<span>劳务关系</span>
								<i class="el-icon-caret-bottom" />
								<span class="border-right" />
							</div>
						</el-popover>
						<el-popover v-if="More" width="60" trigger="click">
							<el-input v-model="tag" placeholder="" size="mini"></el-input>
							<div class="text-grey margin-top-xs" style="text-align: center;">暂无数据</div>
							<div class="cursor" slot="reference">
								<span>标记</span>
								<i class="el-icon-caret-bottom" />
								<span class="border-right" />
							</div>
						</el-popover>
					</div>
					<div class="work-title-search flex flex-wrap-wrap" style="width: 400px">
						<el-input style="width: 300px" placeholder="请输入员工姓名" v-model="query.UserName"
							class="input-with-select" size="medium  ">
							<el-button slot="append" icon="el-icon-search" />
						</el-input>
						<div v-if="!More" @click="moreChange" class="cursor margin-auto text-blue" slot="reference">
							<span>更多筛选</span>
							<i class="el-icon-caret-bottom" />
						</div>
						<div v-if="More" @click="moreChange" class="cursor margin-auto text-blue" slot="reference">
							<span>收起更多</span>
							<i class="el-icon-caret-top" />
						</div>
					</div>
				</div>

				<div class=" flex aligin-items-center justify-content-between ">
					<div class="flex aligin-items-center flex-wrap-wrap">
						<div v-if="orgids.length"
							class="text-gray flex aligin-items-center margin-left-lg margin-top-sm">
							<div>所属部门：</div>
							<div v-for="(btn, btnIndex) in orgids" :key="btnIndex"
								:class="{ 'margin-left': btnIndex > 0 }">
								<el-button size="mini">
									{{ btn }}
									<i class="el-icon-close" @click="del_Choose_Campus(btnIndex, 'orgids')" />
								</el-button>
							</div>
						</div>

						<div v-if="orgids.length"
							class="text-gray flex aligin-items-center margin-left-lg margin-top-sm">
							<div>管辖校区：</div>
							<div v-for="(btn, btnIndex) in orgids" :key="btnIndex"
								:class="{ 'margin-left': btnIndex > 0 }">
								<el-button size="mini">
									{{ btn }}
									<i class="el-icon-close" @click="del_Choose_Campus(btnIndex, 'orgids')"/>
								</el-button>
							</div>
						</div>
						<div v-if="post_type.length"
							class="text-gray flex aligin-items-center margin-left-lg margin-top-sm">
							<div>角色：</div>
							<div v-for="(btn, btnIndex) in post_type" :key="btnIndex"
								:class="{ 'margin-left': btnIndex > 0 }">
								<el-button size="mini">
									{{ btn }}
									<i class="el-icon-close"  @click="del_Choose_Campus(btnIndex, 'post_type')" />
								</el-button>
							</div>
						</div>
						<div v-if="personnel_status.length"
							class="text-gray flex aligin-items-center margin-left-lg margin-top-sm">
							<div>人事状态：</div>
							<div v-for="(btn, btnIndex) in personnel_status" :key="btnIndex"
								:class="{ 'margin-left': btnIndex > 0 }">
								<el-button size="mini">
									{{ btn }}
									<i class="el-icon-close" @click="assistantStatus(btnIndex)" />
								</el-button>
							</div>
						</div>
					</div>
				</div>

				<div class="myClient-text myClient-top-10 margin-left-xs margin-lr-lg">
					<div>
						<el-button size="small">导出当前结果</el-button>

						<el-button size="small">批量调整所属部门</el-button>
					</div>
					<div class="myClient-text flex justify-content-between myClient-top-10">
						当前结果：学员共计{{ total }}名
					</div>
				</div>
				<div style="margin-top: 10px; " class="margin-lr-lg">
					<as-list :ajaxMethods="{ list: 'accountQuery' }" ref="asList" @requestChange="requestChange"
						:listQueryProp="query">
						<el-table-column type="selection" width="55"> </el-table-column>
						<el-table-column prop="full_name" label="员工姓名"></el-table-column>
						<el-table-column prop="sex" label="性别">
							<template slot-scope="scope">
								<span>{{ scope.row.sex | sexFilter }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="phone" label="联系方式"></el-table-column>
						<el-table-column prop="post_type_name" label="职位" :show-overflow-tooltip="true"></el-table-column>
						<el-table-column prop="code" label="工号"></el-table-column>
						<el-table-column prop="birthday" label="生日">
							<template slot-scope="scope">
								<span>{{ scope.row.birthday | dateTimeFilter("yyyy-MM-dd")}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="personnel_status" label="人事状态">
							<template slot-scope="scope">
								<span>{{ scope.row.personnel_status | personnelStatusTypeFilter}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="contract_time_end" label="合同到期">
							<template slot-scope="scope">
								<span>{{ scope.row.contract_time_end | dateTimeFilter("yyyy-MM-dd") }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="user_name" label="机构账号"></el-table-column>
						<el-table-column label="操作">
							<template slot-scope="{ row, $index }">
								<span class="text-blue cursor" @click="goNav(row)">编辑</span>
								<span class="text-blue cursor margin-left"
									@click="detailMessage(row.id, $index)">删除</span>
							</template>
						</el-table-column>
					</as-list>
				</div>
			</el-tab-pane>
			<el-tab-pane label="角色管理" name="third">
				<role-permission></role-permission>
			</el-tab-pane>
			<el-tab-pane label="岗位管理" name="fourth">
				<role-permission :type="1"></role-permission>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>
<script>
	import rolePermission from './components/rolePermission.vue'
	import AsList from "@/components/as-list";

	export default {
		components: {
			AsList,
			rolePermission
		},
		data() {
			return {
				total: '', //总条数
				loading: true,
				OrganizationList: [], //所有部门
				
				More: false,
				assistantInput: "",
				roleMessage: [], //角色列表
				post_type: [],
				birthday: '', //生日日期
				personnel_status: [], //人事状态
				tag:'',
				stuInput: "",
				orgids: [],
				has_social_security: '',
				has_contract: '',
				expire_contract: '',
				labor_relations: [],
				activeName: "first",
			
				listQuerys: {
					type: 1,
					PageIndex: 1,
					PageSize: 15,
					Sort: [{
						Field: "id",
						Type: 0,
					}, ],
				},
				RoleQuery: {
					PageIndex: 1,
					PageSize: 15,
					Sort: [{
						Field: "id",
						Type: 0,
					}, ],
				},

				detailQuery: {
					ids: 0,
					status: 0,
				},
				PersonnelMattersList: [], //人事状态
				organizationList: [], //所有校区
				query: {
					// orgids: [],//组织id
					// personnel_status: [],//人事状态
					// labor_relations: [],//劳动关系
					// // UserName: "",//员工名称
					// // Phone: "",//员工电话
					// birthday: "",//生日
					// expire_contract:'',
					// has_contract:'',//是否签合同
					// has_social_security:'',//是否办理社保
					// tag: "",//标签
					// xpire_contract: "",//合同到期
					// post_type: [],//角色、岗位类型
					has_all: true,
					PageIndex: 1,
					PageSize: 15,
					Sort: [ {
					    "Field": "id",
					    "Type": 0
					}]
				}
			};
		},

		created() {
			this.getOrganizationList()
			this.getType()
			this.getAllRoles()
		},
		methods: {
			requestChange(res) {
				this.total = res.total
			},
			goNav(data) {
				this.$router.push({
					name: 'newEmployee',
					params: {
						detail: data
					},
				});
				localStorage.setItem('EmployeeID', data.id);
			},
			//获取所有的角色
			getAllRoles() {
				this.getRoleQuery({
					PageIndex: 1,
					PageSize: 100000,
				}).then((res) => {
					this.roleMessage = res.data.rows;
				});
			},
			getRoleQuery(data) {
				return new Promise((resolve, reject) => {
					this.API.roleQuery(data).then((res) => {
						resolve(res);
					});
				});
			},
			getType() {
				//人事状态类型 
				this.API.SystemGetSettings('PersonnelMatters', false).then(res => {
					this.PersonnelMattersList = res
				})
				//获取所有校区
				this.API.OrganizationList(this.listQuerys).then(res => {
					this.organizationList = res.data.rows
				})
			},
			//获取所有的部门
			getOrganizationList() {
				let list = {
					type: 2,
					PageIndex: 1,
					PageSize: 15,
					Sort: [{
						"Field": "id",
						"Type": 0
					}]
				}
	
				this.API.OrganizationList(list).then(res => {
					this.OrganizationList = res.data.rows
					var hash = {};
					this.OrganizationList = this.OrganizationList.reduce((item, next) => {
						hash[next.name] ? '' : hash[next.name] = true && item.push(next);
						return item
					}, [])
				})
			},
			moreChange() {
				this.More = !this.More;
			},
			//删除该员工
			detailMessage(id, index) {
				this.$confirm("确定删除该员工?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.detailQuery.ids = id;
					this.detailQuery.status = -99;
					this.API.accountChangeStatus(this.detailQuery).then((res) => {
						if (res.code == 200) {
							this.$message.success("成功删除该用户");
							this.$refs.asList.refresh();
						}
					});
				}).catch(() => {});
			},
			//筛选
			//所属部门
			orgids_change(e) {
				var org_ids = this.OrganizationList.filter((res) => {
					return this.orgids.indexOf(res.name) != -1;
				});
				this.query.orgids = org_ids.map((res) => {
					return res.id;
				});
			},
			//管辖校区
			orgids_change_school(e){
				var org_ids = this.organizationList.filter((res) => {
					return this.orgids.indexOf(res.name) != -1;
				});
				this.query.orgids = org_ids.map((res) => {
					return res.id;
				});
			},
			//角色
			post_type_change(e){ 
				var role_ids = this.roleMessage.filter((res) => {
					return this.post_type.indexOf(res.name) != -1;
				});
				this.query.post_type = role_ids.map((res) => {
					return res.id;
				});
				this.query=JSON.parse(JSON.stringify(this.query));
			 
			},
			//人事状态
			personnel_status_change(e){
				var personnel_id = this.PersonnelMattersList.filter((res) => {
					return this.personnel_status.indexOf(res.Name) != -1;
				});
				this.query.personnel_status = personnel_id.map((res) => {
					return res.Key;
				});
			},
			//生日
			birthday_change(e){
				this.query.birthday=e
			},
			//合同是否签订
			has_contract_change(e){
				this.query.has_contract=e
			},
			//合同到期
			expire_contract_change(e){
				this.query.expire_contract=e
			},
			//社保
			has_social_security_change(e){
				this.query.has_social_security=e
			},
			//劳动关系
			labor_relations_change(e){
				this.query.labor_relations =e
			},
			
			del_Choose_Campus(index, del) {
				
				if (del == "orgids") {
					//清除校区
					this.orgids.splice(index, 1);
					var org_id= this.OrganizationList.filter((rs) => {
						return this.orgids.indexOf(rs.name) != -1;
					});
					this.query.orgids = org_id.map((rs) => {
						return rs.id;
					});
				} else if (del == "post_type") {
					//消除角色
					this.post_type.splice(index, 1);
					var post_types= this.OrganizationList.filter((rs) => {
						return this.post_type.indexOf(rs.name) != -1;
					});
					this.query.post_type = post_types.map((rs) => {
						return rs.id;
					});
				} 
			}
		}
	};
</script>

<style lang="scss">
	.status-point {
		background-color: #04381e;
		margin-right: 5px;
		display: inline-block;
		width: 6px;
		height: 6px;
		border-radius: 50%;
	}

	.height-q {
		height: 100%;
	}

	.total {
		width: 100%;
		height: 100%;
	}

	.padding-30 {
		padding: 30px;
	}

	.padding-40-30 {
		padding: 40px 30px 20px 30px;
	}

	.width-100 {
		width: 100%;
	}

	.total {
		width: 100%;
	}

	.shool {
		margin: 0px 10px;
	}

	.configure {
		width: 100%;
		background-color: #fff;

		padding: 30px 10px 10px 10px;
	}

	.color-box1 {
		color: #fff;
		background-color: #cecece;
	}
</style>
